import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable, Subject } from 'rxjs';
import { API_URL } from '../common/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EllaauthService {
  private token;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }


  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private http: HttpClient) {
   
    const localstorage = new Storage();

  }


  

  /*
  auth_user(postedData): Observable<any> {    
    response= this.httpClient.post('auth/login', postedData);
    if(response.status=!=200){function name(params:type) {
      localStorage.setItem("TOKEN", "rter643gsdhgsdh");
      this.router.navigate(['/dashboard'])
    }}
  }

  auth_user(email, pass) {
    if (email == "rochakchauhanhartron@gmail.com") {
      localStorage.setItem("TOKEN", "rter643gsdhgsdh");
      this.router.navigate(['/dashboard'])
    }

  }

  async auth_token() {
    const token = await localStorage.getItem("TOKEN");
    if (!this.is_valid_token(token)) {      
        this.router.navigate(['/login'])
    }
    return token;
  }
 

  is_valid_token(token){
    if(token=='rter643gsdhgsdh'){
        return true;
    }
    else{
      return false;
    }
  }
   */

  is_logged_in() {
    let user_info = localStorage.getItem("user_info");

    if (user_info == null || user_info == undefined) {
      return false;
    }
    else {
      return true;
    }
  }


  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  apiReq(method: string, endPoint: string, payload?: any, options: any = this.httpOptions): Observable<any> {
    console.log({ method, endPoint, payload });
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL + endPoint, payload);
      case 'post':
        return this.httpClient.post(API_URL + endPoint, payload, options);
      case 'put':
        return this.httpClient.put(API_URL + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(API_URL + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(API_URL + endPoint, payload);
      default:
        return null;
    }
  }

  onError(message): void {
    console.log("SERVICE ERROR: ", message);
  }

//   public page =0;

// setCount(countValue:number){
//   this.page+= countValue; 
// }
// getCount():number{
//   return this.page;
// }

}